import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {MdMenu} from "react-icons/md";
import React, {useState} from "react";
import Drawer from "./Drawer";

const StyledHeaderContainer = styled.header`
  position: sticky;
  top: 0;

  height: calc(var(--header-height) - 1px);
  width: 100vw;

  color: var(--foreground-020);
  background-color: var(--background-020);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  z-index: 1000; /* 다른 요소들 위에 표시 */
`;

const StyledHeaderSideSection = styled.div`
  height: 40px;
  width: calc(var(--header-height) * 2 + 10);
  min-width: calc(var(--header-height) * 2 + 10);

  margin: 0 10px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`

const hoverStyle = `
&:hover, &:active {
    cursor: pointer;
    background-color: var(--background-030);
    transition: 0.5s;
}
`;

interface ButtonProps {
    $clickable: boolean;
}


const StyledHeaderSideButton = styled.button<ButtonProps>`
  height: calc(var(--header-height) - 5px);
  width: calc(var(--header-height) - 5px);

  font-size: 25px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 0;
  margin: 0;

  border: none;
  border-radius: 5px;
  color: var(--foreground-020);
  background-color: var(--background-020);

  ${props => props.$clickable ? hoverStyle : ''}
`

const StyledHeaderTitle = styled.h1`
  margin: 0;
  height: calc(var(--header-height) - 5px);
  width: 150px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  
  font-size: 24px;

  ${hoverStyle}
`

interface HeaderProps {
    leftInnerBtnIcon?: React.ReactNode;
    leftInnerBtnClicked?: () => void;
    rightInnerBtnIcon?: React.ReactNode;
    rightInnerBtnClicked?: () => void;
    rightOuterBtnIcon?: React.ReactNode;
    rightOuterBtnClicked?: () => void;
}


const Header: React.FC<HeaderProps> = (props) => {
    const navigate = useNavigate();
    const [drawerVisibility, setDrawerVisibility] = useState<boolean>(false)

    return (
        <StyledHeaderContainer className="Header">
            <StyledHeaderSideSection>
                <StyledHeaderSideButton
                    $clickable={true}
                    onClick={() => setDrawerVisibility(!drawerVisibility)}
                >
                    <MdMenu/>
                </StyledHeaderSideButton>
                {drawerVisibility &&
                    <Drawer
                        onComplete={() => setDrawerVisibility(false)}
                    />
                }
                <StyledHeaderSideButton
                    $clickable={props.leftInnerBtnIcon !== undefined}
                    onClick={() => {
                        if (props.leftInnerBtnIcon === undefined) return
                        props.leftInnerBtnClicked?.()
                    }}
                >
                    {props.leftInnerBtnIcon}
                </StyledHeaderSideButton>
            </StyledHeaderSideSection>
            <StyledHeaderTitle
                onClick={() => navigate('/')}
            >
                Trade Pilot
            </StyledHeaderTitle>
            <StyledHeaderSideSection>
                <StyledHeaderSideButton
                    $clickable={props.rightInnerBtnIcon !== undefined}
                    onClick={() => {
                        if (props.rightInnerBtnIcon === undefined) return
                        props.rightInnerBtnClicked?.()
                    }}
                >
                    {props.rightInnerBtnIcon}
                </StyledHeaderSideButton>
                <StyledHeaderSideButton
                    $clickable={props.rightOuterBtnIcon !== undefined}
                    onClick={() => {
                        if (props.rightOuterBtnIcon === undefined) return
                        props.rightOuterBtnClicked?.()
                    }}
                >
                    {props.rightOuterBtnIcon}
                </StyledHeaderSideButton>
            </StyledHeaderSideSection>
        </StyledHeaderContainer>
    )
}

export default Header;
