import React from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";

import HomeScreen from './pages/home/HomeScreen';
import LoginScreen from "./pages/users/LoginScreen";
import InvalidScreen from "./pages/globals/InvalidScreen";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={'/'} element={<HomeScreen/>}/>
                <Route path={'/login'} element={<LoginScreen/>}/>
                <Route path={'/*'} element={<InvalidScreen/>}/>
            </Routes>
        </BrowserRouter>
    );

}

export default App;
