import React from "react";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {ReducerType} from "../../app/RootReducer";
import {AuthState} from "../../entities/users/model/AuthSlice";
import {logout} from "../../features/users/model/AuthAction";

const DrawerBackground = styled.div`
  position: absolute;
  top: calc(var(--header-height) - 1px);
  left: 0;

  width: 100vw;
  height: calc(100vh - var(--header-height) - 1px);

  display: flex;
  flex-direction: row;

`

const StyledDrawer = styled.div`
  width: var(--drawer-width);
  height: calc(100vh - var(--header-height) - 1px);

  color: var(--foreground-020);
  background-color: var(--background-020);
  // 오른쪽 그림자
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
  
  z-index: 2000;
`

const StyledDrawerOutside = styled.div`
  width: calc(100vw - var(--drawer-width));

  color: var(--foreground-015);
  background-color: var(--background-015);

  opacity: 0.9;
  
  z-index: 999;
`

const hoverStyle = `
&:hover, &:active {
    cursor: pointer;
    background-color: var(--background-030);
    transition: 0.5s;
}
`;

interface StyledListCellProps {
    $clickable: boolean;
}

const StyledListCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  margin: 5px;
  padding: 5px;
`

const StyledListCellContent = styled.div<StyledListCellProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 100%;
  margin: 0;
  padding: 0;

  border-radius: 5px;

  ${props => props.$clickable ? hoverStyle : ''}
`

const StyledListCellBottomLine = styled.p`
  width: 100%;
  height: 1px;
  margin: 0;
  padding: 0;
  border-bottom: solid 1px var(--background-030);
`

const StyledListCellListBtnText = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 210px;
  height: 35px;

  margin: 0;
  padding: 0 10px;
  font-weight: 800;
`

const StyledProfile = styled.div`
  width: 210px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 10px;
`

const Nickname = styled.h1`
  width: 200px;
  height: 100px;

  margin: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const LoginBtn = styled.button`
  width: 200px;
  height: 30px;

  border: none;
  border-radius: 15px;

  color: var(--foreground-020);
  background-color: var(--background-020);

  &:hover, &:active {
    cursor: pointer;
    background-color: var(--background-030);
    transition: 0.5s;
  }
`

interface ListCellProps {
    clickable?: boolean
    children: React.ReactNode
}

const ListCell: React.FC<ListCellProps> = (props) => {
    return (
        <StyledListCell>
            <StyledListCellContent $clickable={props.clickable ?? true}>
                {props.children}
            </StyledListCellContent>
            <StyledListCellBottomLine/>
        </StyledListCell>
    )
}

interface DrawerProps {
    onComplete: () => void
}

const Drawer: React.FC<DrawerProps> = (props) => {
    const auth = useSelector<ReducerType, AuthState | null>(state => state.Auth)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isLogin = auth?.nickname !== undefined
    return (
        <DrawerBackground>
            <StyledDrawer>
                <ListCell clickable={false}>
                    <StyledProfile>
                        <Nickname>
                            {isLogin ? auth.nickname : "Guest"}
                        </Nickname>
                        <LoginBtn
                            onClick={async () => {
                                if (isLogin) {
                                    await logout(dispatch)
                                    navigate('/')
                                } else {
                                    navigate('/login')
                                }
                            }}
                        >
                            {isLogin ? "Logout" : "Login"}
                        </LoginBtn>

                    </StyledProfile>
                </ListCell>
                <ListCell>
                    <StyledListCellListBtnText onClick={() => {
                        props.onComplete()
                        if ('caches' in window) {
                            caches.keys().then((names) => {
                                names.forEach((name) => {
                                    caches.delete(name);
                                });
                            });
                        }

                        window.location.reload();
                    }}>
                        앱 새로고침
                    </StyledListCellListBtnText>
                </ListCell>
                <ListCell>
                    <StyledListCellListBtnText onClick={() => {
                        props.onComplete()
                        navigate("/task")
                    }}>
                        작업
                    </StyledListCellListBtnText>
                </ListCell>
                <ListCell>
                    <StyledListCellListBtnText>
                        Btn
                    </StyledListCellListBtnText>
                </ListCell>
            </StyledDrawer>
            <StyledDrawerOutside
                onClick={props.onComplete}
            />
        </DrawerBackground>
    )
}

export default Drawer
