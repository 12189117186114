import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {UserRole} from "./AuthType";

export interface AuthState {
    nickname: String
    accessToken: String
    accessTokenExpirationDate: String
    refreshToken: String,
    refreshTokenExpirationDate: String,
    roles: UserRole[]
}

export const DefaultAuth: AuthState | null = null

export const AuthSlice = createSlice({
    name: "Auth",
    initialState: DefaultAuth as AuthState | null,
    reducers: {
        updateAuth(state, action: PayloadAction<AuthState>) {
            return action.payload;
        },
        clearAuth() {
            return DefaultAuth
        }
    }
})

export const {updateAuth, clearAuth, } = AuthSlice.actions;
export default AuthSlice.reducer;
