import Header from "../../widgets/globals/Header";
import React from "react";

function HomeScreen() {
    return (
        <div>
            <Header/>
            Home
        </div>
    )
}

export default HomeScreen;
