import {AuthType, Token, UserRole} from "./AuthType";
import axios from "axios";
import ServerProperty from "../../../shared/property/ServerProperty";
import {OffsetDateTime} from "../../../shared/type/OffsetDateTime";
import handleException from "../../../shared/exception/AxiosExceptionHandler";

interface AuthResponse {
    nickname: String,
    accessToken: String,
    accessTokenExpirationTime: String,
    refreshToken: String,
    refreshTokenExpirationTime: String,
    roles: String[]
}

export async function requestLogin(id: string, pw: string): Promise<AuthType> {
    try {
        const body = {
            account: id,
            password: pw
        }
        const res = await axios.post(`${ServerProperty.BASE_URL}/composite/auth/login`, body)
        const response = res.data as AuthResponse;

        return new AuthType(
            response.nickname,
            new Token(response.accessToken, OffsetDateTime.parseOffsetDateTime(response.accessTokenExpirationTime)!!),
            new Token(response.refreshToken, OffsetDateTime.parseOffsetDateTime(response.refreshTokenExpirationTime)!!),
            response.roles as UserRole[]
        )
    } catch (err) {
        throw handleException(err)
    }
}

export async function requestRefresh(refreshToken: String): Promise<Token> {
    try {
        const header = {
            'Authorization': `Bearer ${refreshToken}`
        }
        const res = await axios.post(`${ServerProperty.BASE_URL}/composite/auth/refresh`, null, {headers: header})

        return res.data as Token;
    } catch (err) {
        throw handleException(err)
    }
}
