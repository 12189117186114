import {requestLogin, requestRefresh} from "../../../entities/users/model/AuthApi";
import {clearAuth, updateAuth} from "../../../entities/users/model/AuthSlice";
import {Dispatch} from "@reduxjs/toolkit";
import {AuthType, Token} from "../../../entities/users/model/AuthType";
import {isExpire} from "../libs/AuthLibs";

export async function login(
    dispatch: Dispatch,
    id: string,
    pw: string
): Promise<void> {

    const auth = await requestLogin(id, pw)

    dispatch(updateAuth(auth.toState()))
}

export async function refreshToken(
    dispatch: Dispatch,
    auth: AuthType
): Promise<Token> {
    if (isExpire(auth.access)) {
        const refreshedAccess = await requestRefresh(auth.refresh.value)

        const updatedAuth = auth.updateAccess(refreshedAccess)
        dispatch(updateAuth(updatedAuth.toState()))

        return refreshedAccess
    } else {
        return auth.access
    }

}

export async function logout(
    dispatch: Dispatch
): Promise<void> {
    dispatch(clearAuth())
}
